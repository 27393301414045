/**
 * The Root container
 */
import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Loadable from "react-loadable";
import FourOFour from "./utils/FourOFour";
import BetaFeature from "./utils/BetaFeature";
import LicenseFeature from "./utils/LicenseFeature";
import { keepWalletConnected } from "@bkry/bowline-utils";
import { useWeb3React } from "@web3-react/core";
import { metaMask } from "@bkry/bowline-connectors";
// import { push } from "connected-react-router";
import CreatorLayoutRoute from "./Layouts/CreatorLayoutRoute";
import ContentCategoriesRoot from "./ContentCategories/ContentCategoriesRoot";
import { useSelector } from "react-redux";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";

const TokensRoot = Loadable({
  loader: () => import(/* webpackChunkName: "Tokens" */ "./Tokens/TokensRoot"),
  loading: () => null,
  modules: ["Tokens"],
});

const WhitelistRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Whitelist" */ "./Whitelist/WhitelistRoot"),
  loading: () => null,
  modules: ["Whitelist"],
});

const HoldersRoot = Loadable({
  loader: () => import(/* webpackChunkName: "Holders" */ "./Holders/Holders"),
  loading: () => null,
  modules: ["Holders"],
});

const ProjectRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Project" */ "./Project/ProjectRoot"),
  loading: () => null,
  modules: ["Project"],
});

const ProjectOrdersRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProjectOrders" */ "./ProjectOrders/ProjectOrdersRoot"
    ),
  loading: () => null,
  modules: ["ProjectOrders"],
});

const PushLinksRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "PushLinks" */ "./PushLinks/PushLinksRoot"),
  loading: () => null,
  modules: ["PushLinks"],
});

const OnBoarding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "OnBoarding" */ "./OnBoarding/OnBoarding-Root"),
  loading: () => null,
  modules: ["OnBoarding-Root"],
});

const UnlockableContentsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UnlockableContents" */ "./UnlockableContents/UnlockableContentsRoot"
    ),
  loading: () => null,
  modules: ["UnlockableContents"],
});

const VotingsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Votings" */ "./Votings/VotingsRoot"),
  loading: () => null,
  modules: ["Votings"],
});

const EventsRoot = Loadable({
  loader: () => import(/* webpackChunkName: "Events" */ "./Events/EventsRoot"),
  loading: () => null,
  modules: ["Events"],
});

const RafflesRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Raffles" */ "./Raffles/RafflesRoot"),
  loading: () => null,
  modules: ["Raffles"],
});

const DiscussionsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Discussions" */ "./Discussions/DiscussionsRoot"
    ),
  loading: () => null,
  modules: ["Discussions"],
});

const ContractsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Contracts" */ "./Contracts/ContractsRoot"),
  loading: () => null,
  modules: ["Contracts"],
});
const UtilityContractsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UtilityContracts" */ "./UtilityContracts/UtilityContractsRoot"
    ),
  loading: () => null,
  modules: ["UtilityContracts"],
});

const MintTiersRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MintTiers" */ "./MintTiers/MintTiersRoot"),
  loading: () => null,
  modules: ["MintTiers"],
});

const PartnerDealsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PartnerDeals" */ "./PartnerDeals/PartnerDealsRoot"
    ),
  loading: () => null,
  modules: ["PartnerDeals"],
});

const WalletDetail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "WalletDetail" */ "./Wallets/WalletDetail"),
  loading: () => null,
  modules: ["WalletDetail"],
});

const PageBuilderDetail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PageBuilderDetail" */ "./PageBuilder/PageBuilderDetail"
    ),
  loading: () => null,
  modules: ["PageBuilderDetail"],
});

const PageBuilderCreate = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PageBuilderCreate" */ "./PageBuilder/PageBuilderCreate"
    ),
  loading: () => null,
  modules: ["PageBuilderCreate"],
});

const PageBuilderRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PageBuilderRoot" */ "./PageBuilder/PageBuilderRoot"
    ),
  loading: () => null,
  modules: ["PageBuilderRoot"],
});

const NavigationItemRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "NavigationItemRoot" */ "./NavigationItems/NavigationItemsRoot"
    ),
  loading: () => null,
  modules: ["NavigationItemRoot"],
});

const WalletProfileInputRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "WalletProfileInputRoot" */ "./WalletProfileInputs/WalletProfileInputsRoot"
    ),
  loading: () => null,
  modules: ["WalletProfileInputRoot"],
});

const ProjectSettingsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProjectSettingsRoot" */ "./ProjectSettings/ProjectSettingsRoot"
    ),
  loading: () => null,
  modules: ["ProjectSettingsRoot"],
});
const ProjectBillingInfo = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProjectBillingInfo" */ "./ProjectBillingInfos/ProjectBillingInfo"
    ),
  loading: () => null,
  modules: ["ProjectBillingInfo"],
});
const CollaboratorsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CollaboratorsRoot" */ "./Collaborators/CollaboratorsRoot"
    ),
  loading: () => null,
  modules: ["CollaboratorsRoot"],
});

const TraitTypesRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "TraitTypes" */ "./TraitTypes/TraitTypesRoot"),
  loading: () => null,
  modules: ["TraitTypes"],
});

const CommunityAnnouncementsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CommunityAnnouncements" */ "./CommunityAnnouncements/CommunityAnnouncementsRoot"
    ),
  loading: () => null,
  modules: ["CommunityAnnouncements"],
});

const ProjectSetupRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TraitTypes" */ "./ProjectSetup/ProjectSetupRoot"
    ),
  loading: () => null,
  modules: ["ProjectSetupRoot"],
});

const AccountSetupRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountSetup" */ "./AccountSetup/AccountSetupRoot"
    ),
  loading: () => null,
  modules: ["AccountSetup"],
});

const TribesRoot = Loadable({
  loader: () => import(/* webpackChunkName: "Tribes" */ "./Tribes/TribesRoot"),
  loading: () => null,
  modules: ["Tribes"],
});

const RoadmapsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Roadmaps" */ "./Roadmaps/RoadmapsRoot"),
  loading: () => null,
  modules: ["Roadmaps"],
});

const FaqsRoot = Loadable({
  loader: () => import(/* webpackChunkName: "FaqsRoot" */ "./Faqs/FaqsRoot"),
  loading: () => null,
  modules: ["FaqsRoot"],
});

const FaqListsRoot = Loadable({
  loader: () =>
    import(/* webpackChunkName: "FaqListsRoot" */ "./FaqLists/FaqListsRoot"),
  loading: () => null,
  modules: ["FaqListsRoot"],
});

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
const Root = () => {
  const { account, connector, ...rest } = useWeb3React();
  const { activate } = metaMask;
  useEffect(() => {
    keepWalletConnected(account, activate, connector, rest);
  }, [account, activate]);

  const currentCreatorId = useSelector(
    (state) => state.creators.currentCreatorId
  );

  const creatorConfirmed = useSelector((state) => state.creators.confirmed);

  //code below to get url because useParams not working on Root
  const { pathname } = useLocation();
  const projectId =
    pathname.split("/projects/").pop().split("/").length > 0
      ? pathname.split("/projects/").pop().split("/")[0]
      : null;

  const projectData = useSelector((state) =>
    getProjectAttributes(state, projectId)
  );

  return (
    <StickyContainer id="content">
      <Switch>
        <Route path="/onboarding" component={OnBoarding} />
        {currentCreatorId && currentCreatorId !== account && (
          <Redirect to="/onboarding/metamask" />
        )}
        <Route exact path="/">
          <Redirect to="/onboarding/metamask" />
        </Route>

        <Route path="/emailsetup" component={AccountSetupRoot} />
        {account && creatorConfirmed !== null && !creatorConfirmed && (
          <Redirect to="/emailsetup" />
        )}

        <Route path="/setup" component={ProjectSetupRoot} />

        <CreatorLayoutRoute
          path="/projects/:projectId/contracts/:contractAddress/tokens"
          component={
            projectData?.license_rules?.token_management
              ? TokensRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/tokens"
          component={
            projectData?.license_rules?.token_management
              ? TokensRoot
              : LicenseFeature
          }
        />

        <CreatorLayoutRoute
          exact
          path="/projects/:projectId/allowlist"
          component={
            projectData?.license_rules?.allowlist_management
              ? WhitelistRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/exchange"
          component={BetaFeature}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/shop"
          component={BetaFeature}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/analytics"
          component={BetaFeature}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/votings"
          component={
            projectData?.license_rules?.unlockables_management
              ? VotingsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/contents"
          component={
            projectData?.license_rules?.unlockables_management
              ? UnlockableContentsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/discussions"
          component={
            projectData?.license_rules?.unlockables_management
              ? DiscussionsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/events"
          component={
            projectData?.license_rules?.unlockables_management
              ? EventsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/raffles"
          component={
            projectData?.license_rules?.unlockables_management
              ? RafflesRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/tiers"
          component={
            projectData?.license_rules?.contract_management
              ? MintTiersRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/contracts/:contractAddress/tiers"
          component={
            projectData?.license_rules?.contract_management
              ? MintTiersRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/deals"
          component={
            projectData?.license_rules?.unlockables_management
              ? PartnerDealsRoot
              : LicenseFeature
          }
        />

        <CreatorLayoutRoute
          path="/projects/:projectId/announcements"
          component={CommunityAnnouncementsRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/contracts/:contractAddress/traittypes"
          component={TraitTypesRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/billing"
          component={ProjectBillingInfo}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/project_orders"
          component={ProjectOrdersRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/push_links"
          component={PushLinksRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/settings"
          component={ProjectSettingsRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/collaborators"
          component={CollaboratorsRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/categories"
          component={ContentCategoriesRoot}
        />
        <CreatorLayoutRoute
          exact
          path="/projects/:projectId/holders"
          component={HoldersRoot}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/contracts"
          component={
            projectData?.license_rules?.contract_management
              ? ContractsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path={`/projects/:projectId/pages/new`}
          component={
            projectData?.license_rules?.dapp_management
              ? PageBuilderCreate
              : LicenseFeature
          }
          fluidContainer
          sidebarHidden
        />
        <CreatorLayoutRoute
          path={`/projects/:projectId/locale/:locale/pages/:pageId`}
          component={
            projectData?.license_rules?.dapp_management
              ? PageBuilderDetail
              : LicenseFeature
          }
          fluidContainer
          sidebarHidden
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/pages"
          component={
            projectData?.license_rules?.dapp_management
              ? PageBuilderRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/navigation-items"
          component={
            projectData?.license_rules?.dapp_management
              ? NavigationItemRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/faqs"
          component={
            projectData?.license_rules?.dapp_management
              ? FaqsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/faqlists"
          component={
            projectData?.license_rules?.dapp_management
              ? FaqListsRoot
              : LicenseFeature
          }
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/utility-contracts"
          component={
            projectData?.license_rules?.contract_management
              ? UtilityContractsRoot
              : LicenseFeature
          }
        />

        <CreatorLayoutRoute
          path="/projects/:projectId/tribes"
          component={TribesRoot}
        />

        <CreatorLayoutRoute
          path="/projects/:projectId/roadmaps"
          component={RoadmapsRoot}
        />

        <CreatorLayoutRoute
          exact
          path="/projects/:projectId/allowlist/wallets/:walletAddress"
          component={WalletDetail}
        />
        <CreatorLayoutRoute
          exact
          path="/projects/:projectId/holders/wallets/:walletAddress"
          component={WalletDetail}
        />
        <CreatorLayoutRoute
          path="/projects/:projectId/wallet-profile-inputs"
          component={WalletProfileInputRoot}
        />
        <CreatorLayoutRoute exact path="/projects" component={ProjectRoot} />
        <CreatorLayoutRoute
          path="/projects/:projectId"
          component={ProjectRoot}
        />
        <Route component={FourOFour} />
      </Switch>
    </StickyContainer>
  );
};

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */

/** export the redux connected component and add a frontload call */
export default withRouter(Root);
